import "../stylesheets/application";
// import '../js/bootstrap_js_files.js'
require("bootstrap/dist/js/bootstrap")

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

var jQuery = require('jquery')
  global.$ = global.jQuery = jQuery;
  window.$ = window.jQuery = jQuery;

jQuery(document).ready(function($) {
  $('[data-toggle="tooltip"]').tooltip();
});